<template>
  <b-row>
    <b-col
      cols="12"
      xl="9"
      lg="8"
      md="7"
    >
      <b-card>
    
        <b-row>
      
          <!-- User Info: Left col -->
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start align-items-center">
              <avatar></avatar>
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0">
                    Kuzmina Swetlana
                  </h4>
                  <span class="card-text">test@mail.ru</span>
                </div>
                <div class="d-flex flex-wrap">
                
                </div>
              </div>
            </div>
            
          </b-col>
      
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">ID</span>
                </th>
                <td class="pb-50">
                  1
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="MailIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Login/Email</span>
                </th>
                <td class="pb-50 text-capitalize">
                  Test@mail.ru
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="KeyIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Пароль</span>
                </th>
                <td class="pb-50 text-capitalize">
                  12345678
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="ClockIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Создан</span>
                </th>
                <td class="pb-50">
                  12.01.2022
                </td>
              </tr>
              
              <tr>
                <th>
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Состояние</span>
                </th>
                <td>
                  <b-badge variant="danger">
                    Удален
                  </b-badge>
                </td>
              </tr>
  
              <tr>
                <th>
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Состояние</span>
                </th>
                <td>
                  <b-badge variant="success">
                    Активен
                  </b-badge>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="5"
      xl="3"
      lg="4"
    >
    </b-col>
  </b-row>
</template>

<script>
import {
    BRow, BCol, BAlert, BLink, BBadge, BCard
} from 'bootstrap-vue'
import Avatar from './Avatar'

export default {
  name: "User",
  components: {
      BRow,
      BCol,
      BAlert,
      BLink,
		  Avatar,
		  BBadge,
		  BCard
  },
}
</script>

<style scoped>

</style>